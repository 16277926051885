package de.haukesomm.website

import de.haukesomm.website.components.gitHubProjectCard
import de.haukesomm.website.components.headline
import de.haukesomm.website.components.textPill
import de.haukesomm.website.data.GitHubUserStore
import de.haukesomm.website.data.Skill
import dev.fritz2.core.RenderContext
import kotlinx.coroutines.flow.map

fun RenderContext.mainPage() {
    div("space-y-8") {
        headline("""Hey, I'm Hauke! 👋🏻👨🏼‍💻🌊⚓️🪴""")
        
        div("leading-relaxed space-y-4") {
            p {
                +"""I'm a professional full-stack software engineer based in the heart of Hamburg, Germany. 
                    | Focusing on web technologies, I am passionate about building high-quality software that is easy
                    | to maintain and fun to use. Additionally, I'm a dedicated advocate for free and open-source
                    | software, as well as an open web.
                """.trimMargin()
            }
        }

        div("space-y-4") {
            headline("Skills")

            div("flex flex-wrap gap-1") {
                Skill.skills.forEach { (name, slug, color, highlight) ->
                    textPill(name, highlight, slug, color)
                }
            }
        }
        
        div("space-y-4 transition-opacity ease-in-out delay-150 duration-300") { 
            val userStore = GitHubUserStore(job)
            userStore.load(Unit)
            
            className(userStore.busy.map { 
                if (it) "opacity-0" else "opacity-100"
            }, initial = "opacity-0")
            
            userStore.data.renderNotNull(into = this) {
                headline("Featured GitHub Projects")
                
                div("grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2") {
                    it.pinnedItems.nodes.forEach { project ->
                        gitHubProjectCard(project)
                    }
                }
            }
        }
    }
}