package de.haukesomm.website.model

import dev.fritz2.core.Lenses
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@Lenses
data class ProjectLanguage(
    val name: String,
    val color: String
) {
    companion object
}

@Serializable
@Lenses
data class ProjectOwner(
    val login: String,
    val url: String
) {
    companion object
}

@Serializable
@Lenses
data class ProjectCollaborator(
    val login: String,
) {
    companion object
}

@Serializable
@Lenses
data class ProjectCollaborators(
    val nodes: List<ProjectCollaborator>
) {
    companion object
}

@Serializable
@Lenses
data class GitHubProject(
    val name: String,
    val description: String,
    @SerialName("stargazerCount")
    val stars: Int,
    val url: String,
    val homepageUrl: String?,
    val primaryLanguage: ProjectLanguage,
    val owner: ProjectOwner,
    val collaborators: ProjectCollaborators
) {
    companion object
}

@Serializable
@Lenses
data class GitHubProjects(
    val nodes: List<GitHubProject>
) {
    companion object
}

@Serializable
@Lenses
data class GitHubUser(
    val login: String,
    val pinnedItems: GitHubProjects
) {
    companion object
}
