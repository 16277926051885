package de.haukesomm.website.data

import de.haukesomm.website.model.GitHubUser
import dev.fritz2.core.Handler
import dev.fritz2.core.RootStore
import dev.fritz2.tracking.tracker
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject

class GitHubUserStore(job: Job) : RootStore<GitHubUser?>(null, job) {
    
    private val tracker = tracker()
    val busy: Flow<Boolean> = tracker.data
    
    val load: Handler<Unit> = handle { _, _ ->
        tracker.track {
            val text = window
                .fetch("https://rzyzvohknkjsmvsefsye.supabase.co/functions/v1/featured-github-projects")
                .await()
                .text()
                .await()

            val json = Json.parseToJsonElement(text)

            json.jsonObject["data"]?.jsonObject?.get("user")?.let { jsonUser ->
                Json.decodeFromJsonElement(GitHubUser.serializer(), jsonUser)
            }
        }
    }
}