package de.haukesomm.website.components

import dev.fritz2.core.RenderContext
import dev.fritz2.core.joinClasses

fun RenderContext.textPill(
    text: String,
    highlight: Boolean = false,
    simpleIconsSlug: String? = null,
    color: String? = null
) {
    div(
        joinClasses(
            "p-0.5 relative rounded-full",
            if (highlight) {
                joinClasses(
                    "bg-gradient-to-r",
                    "from-primary-400 to-secondary-400 dark:from-primary-600 dark:to-secondary-600"
                )
            } else {
                "bg-gray-200 dark:bg-gray-800"
            },
        )
    ) {
        div(
            joinClasses(
                "px-3 py-1 flex flex-row gap-2 items-center rounded-full bg-background",
            )
        ) {
            simpleIconsSlug?.let { slug ->
                simpleIcon(slug, "w-4 h-4", color)
            }
            +text
        }
    }
}