package de.haukesomm.website

import de.haukesomm.website.components.linkedInIcon
import de.haukesomm.website.components.simpleIcon
import dev.fritz2.core.*
import kotlin.js.Date

fun main() {
    render {
        main("flex min-h-screen px-8 md:px-24 pb-4 md:py-12 flex-col items-center") {
            div("w-full flex flex-col gap-12 max-w-4xl") {
                div("sticky z-10 top-0 px-6 -mx-6 bg-page") {
                    div(
                        joinClasses(
                            "py-4  px-4 -mx-4 flex justify-between items-center flex-wrap gap-y-4",
                            "border-b border-gray-300 dark:border-gray-700"
                        )
                    ) {
                        h1("w-max text-lg font-semibold whitespace-nowrap") {
                            +"Hauke Sommerfeld"
                        }
                        div("flex flex-row items-center gap-6") {
                            a {
                                href("https://www.linkedin.com/in/hauke-sommerfeld-bb47aa268")
                                target("_blank")
                                linkedInIcon("size-7")
                            }
                            a {
                                href("https://github.com/haukesomm")
                                target("_blank")
                                simpleIcon("github", "size-6")
                            }
                        }
                    }
                }

                mainPage()

                div(
                    joinClasses(
                        "flex flex-row flex-wrap justify-center items-center gap-x-4 gap-y-2",
                        "text-sm text-gray-600 dark:text-gray-400 whitespace-nowrap"
                    )
                ) {
                    span {
                        +"© ${Date().getFullYear()}, Hauke Sommerfeld"
                    }
                    span {
                        +"Made with ♡ in Hamburg, Germany"
                    }
                }
            }
        }
    }
}