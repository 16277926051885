package de.haukesomm.website.components

import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.joinClasses
import dev.fritz2.core.target

fun RenderContext.pillShapedLinkButton(href: String, title: String) {
    a(
        joinClasses(
            "px-2 py-1 flex flex-row gap-2 justify-center items-center",
            "border dark:border-gray-600 rounded-full bg-gray-100 dark:bg-gray-800 text-sm",
            "transition duration-200 hover:scale-105"
        )
    ) {
        href(href)
        target("_blank")
        
        +title
    }
}