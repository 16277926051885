package de.haukesomm.website.data

data class Skill(
    val name: String,
    val simpleIconsSlug: String? = null,
    val hexColor: String? = null,
    val highlight: Boolean = false
) {
    companion object {
        val skills: List<Skill> = listOf(
            Skill("Kotlin", "kotlin", "#7F52FF", highlight = true),
            Skill("Java", "openjdk", "#F78C40", highlight = true),
            Skill("Spring", "spring", "#6DB33F", highlight = true),
            Skill("GitHub", "github", highlight = true),
            Skill("HTML5", "html5", "#E34F26"),
            Skill("CSS", "css", "#663399"),
            Skill("Rust (learning)", "rust", "#DEA685"),
            Skill("Open-Source Software"),
            Skill("Linux"),
            Skill("Microsoft Azure"),
        )
    }
}