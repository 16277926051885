package de.haukesomm.website.components

import de.haukesomm.website.model.GitHubProject
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href

fun RenderContext.gitHubProjectCard(project: GitHubProject) {
    div("w-full h-fit p-4 flex flex-col gap-4 rounded-xl border dark:border-gray-700 bg-background shadow-sm") {
        div("flex flex-row gap-2 justify-between items-center") {
            p("font-semibold") { 
                +project.name 
            }
            /*div("px-1 border dark:border-gray-700 rounded-full text-xs") { 
                +"""{repositoryRoleForUsername(username, repo)}""" 
            }*/
        }
        
        p { +project.description }
        
        if (!project.homepageUrl.isNullOrBlank()) {
            pillShapedLinkButton(project.homepageUrl, "Project Homepage")
        }
        pillShapedLinkButton(project.url, "View on GitHub")
        
        div("flex flex-row flex-wrap gap-x-4 gap-y-1 items-center") {
            div("flex flex-row gap-2 items-center") {
                div("w-3 h-3 rounded-full") {
                    inlineStyle("background-color: ${project.primaryLanguage.color}")
                }
                span("text-sm") { 
                    +project.primaryLanguage.name
                }
            }
            div("flex flex-row gap-1 items-center text-sm") {
                span("text-yellow-500") { 
                    +"★"
                }
                span { 
                    +project.stars.toString()
                }
            }
            div("flex flex-row gap-2 items-center") {
                a("text-sm") {
                    href(project.owner.url)
                    +project.owner.login
                }
            }
        }
    }
}